<template>
<a-layout>
    <a-layout-content>
        <a-row class="history" v-bind:class="[ _mobile  ? 'mobile' : 'pc' ]" style="margin-top:150px;margin-bottom:100px;overflow-x:scroll">
            <a-col :span="24">
                <div class="s-target-h-font1">신청 이력</div>
            </a-col>
            <a-col :span="24" v-if="_mobile">
                <div style="margin-top:16px;">
                    <a-select default-value="전체" style="width: 98%;z-index:9" size="large" @change="onSelectStoreType">
                        <a-select-option :value="item.value" v-for="item in storelist" :key="item">
                            <span role="img" v-if="item.src">
                                <img class="smalllogo" :src="item.src" />&nbsp;
                            </span>{{item.label}}
                        </a-select-option>
                    </a-select>
                </div>
                <div style="margin-top:20px;margin-left:0px;margin-right:9px;padding-top:10px;margin-bottom:10px">
                    기간 설정
                </div>
                <div style="display:flex;width:97%">
                    <div style="margin-top:0px;z-index: 9;">
                        <a-date-picker :defaultValue="date.fromdate" @change="onChangeDateFrom" format="YYYY-MM-DD" placeholder="시작 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
                    </div>
                    <div style="margin-top:0;margin-left:5px;margin-right:5px;padding-top:10px">
                        ~
                    </div>
                    <div style="margin-top:0px;z-index: 9;">
                        <a-date-picker :defaultValue="date.todate" @change="onChangeDateTo" format="YYYY-MM-DD" placeholder="종료 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
                    </div>
                    <div class="s-h-btn1" @click="loadDefaultInfo()" style="margin-top:0px;;margin-left:5px;z-index: 9;">
                        <a-button style="width:60px">검색</a-button>
                    </div>
                </div>
                <div class="s-h-btn2" style="width:97%;margin-top:20px;margin-bottom:20px">
                    <a-checkbox default-checked style="width:100%;margin-bottom:10px" @change="onSelectCheckBox">전체 채크 여부</a-checkbox>
                    <a-button @click="onLoadGroupView()" style="width:100%;cursor:pointer;z-index:9"><i class="fal fa-file-excel">&nbsp;</i>선택한 캠페인 결과 다운로드</a-button>
                </div>

            </a-col>
            <a-col :span="24" v-else style="min-width:1050px">

                <div style="display:flex">
                    <div style="margin-top:20px;z-index: 9;">
                        <a-select default-value="전체" style="width: 180px" size="large" @change="onSelectStoreType">

                            <a-select-option :value="item.value" v-for="item in storelist" :key="item">
                                <span role="img" v-if="item.src">
                                    <img class="smalllogo" :src="item.src" />&nbsp;
                                </span>{{item.label}}
                            </a-select-option>

                        </a-select>
                    </div>
                    <div style="margin-top:20px;margin-left:20px;margin-right:9px;padding-top:10px">
                        기간 설정
                    </div>
                    <div style="margin-top:20px;z-index: 9;">
                        <a-date-picker :defaultValue="date.fromdate" @change="onChangeDateFrom" format="YYYY-MM-DD" placeholder="시작 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
                    </div>
                    <div style="margin-top:20;margin-left:10px;margin-right:10px;padding-top:30px">
                        ~
                    </div>
                    <div style="margin-top:20px;z-index: 9;">
                        <a-date-picker :defaultValue="date.todate" @change="onChangeDateTo" format="YYYY-MM-DD" placeholder="종료 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
                    </div>
                    <div class="s-h-btn1" @click="loadDefaultInfo()" style="margin-top:20px;margin-left:10px;z-index: 9;">
                        <a-button style="width:60px">검색</a-button>
                    </div>

                    <div class="s-h-btn2" style="right:0;position:absolute;margin-right:20px;margin-top: 20px;z-index: 9;display:flex">
                        <a-checkbox default-checked style="width:100%;margin-top:10px" @change="onSelectCheckBox">전체 채크 여부</a-checkbox>
                        <a-button @click="onLoadGroupView()" style="width:100%;cursor:pointer;"><i class="fal fa-file-excel">&nbsp;</i>선택한 캠페인 결과 다운로드</a-button>
                    </div>

                </div>
            </a-col>
            <a-row>
                <a-col :span="span" v-for="item in applyData" :key="item.key" v-if="item.data.status!='R' &&item.data.status!='D'" style="padding-right:10px">
                    <div class="s-target-h-b1">
                        <div style="display:flex">

                            <div v-if="item.data.status=='A'">
                                <a-tag color="red">지원[미결제]</a-tag>
                            </div>
                            <div v-if="item.data.status=='C'">
                                <a-tag color="orange">결제완료</a-tag>
                            </div>
                            <div v-if="item.data.status=='E'">
                                <a-tag color="green">이벤트지원</a-tag>
                            </div>
                            <div v-if="item.data.status=='F'">
                                <a-tag color="cyan">승인중</a-tag>
                            </div>
                            <div v-if="item.data.status=='W'">
                                <a-tag color="blue">오픈대기</a-tag>
                            </div>
                            <div v-if="item.data.status=='P'">
                                <a-tag color="purple">진행중</a-tag>
                            </div>
                            <div v-if="item.data.status=='B'">
                                <a-tag color="purple">진행중</a-tag>
                            </div>
                            <div v-if="item.data.status=='T'">
                                <a-tag color="purple">진행중</a-tag>
                            </div>
                            <div v-if="item.data.status=='X'">
                                <a-tag color="#108ee9">캠페인완료</a-tag>
                            </div>
                            <div v-if="item.data.status=='COPY'">
                                <a-tag color="gray">복제</a-tag>
                            </div>
                            <div style="right:0;position:absolute;padding-right:30px;display:flex">

                                <div style="margin-right:10px">
                                    <a-checkbox v-model="item.data.chkd"></a-checkbox>
                                </div>
                                <div style="margin-top:-5px">
                                    <a-dropdown>
                                        <a class="ant-dropdown-link" style="color:black;font-size:20px;font-weight:bold">
                                            <a-icon type="ellipsis" />
                                        </a>
                                        <a-menu slot="overlay" @click="onClickMenu($event,item.key)">
                                            <a-menu-item key="1">지원 이력</a-menu-item>
                                            <a-menu-item key="2" :disabled="item.data.status!='E'&&item.data.status!='A'&&item.data.status!='C'&&item.data.status!='F'">삭제하기</a-menu-item>
                                            <a-menu-item key="3">캠페인 이력조회</a-menu-item>
                                            <a-menu-item key="4">캠페인 복사하기</a-menu-item>
                                            <a-menu-item key="5">캠페인 리포트</a-menu-item>
                                            <a-menu-item key="6">캠페인 리포트(대행사)</a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="s-target-h-font2" style="margin-top:6px">{{item.data.sname}}&nbsp;[{{item.data.storetype}}]</div>
                            <div class="s-target-h-font3" style="margin-top:3px"> {{item.data.keyword}}</div>
                            <div class="s-target-h-font4" style="margin-top:13px">캠페인 번호 &nbsp; {{item.data.order}}</div>
                            <div class="s-target-h-font5" style="margin-top:3px"> 지원 일시 : &nbsp; {{item.data.eventdttm}}</div>
                            <div class="s-target-h-font6" style="margin-top:12px"> {{item.data.desc}}</div>
                            <div style="border-bottom:1px solid #eaeaea;padding-bottom:10px;width:100%"></div>
                            <div style="margin-top:10px">

                                <a-row>
                                    <a-col :span="12" v-if="item.data.c1">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c1}}&nbsp;</span><span v-if="item.data.c1" class="s-target-h-font8">{{item.data.n1}}/{{item.data.c1}}&nbsp;&nbsp;</span>
                                    </a-col>

                                    <a-col :span="12" v-if="item.data.c2">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c2}}&nbsp;</span><span v-if="item.data.c2" class="s-target-h-font8">{{item.data.n2}}/{{item.data.c2}}&nbsp;&nbsp;</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c4">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c4}}&nbsp;</span><span v-if="item.data.c4" class="s-target-h-font8">{{item.data.n4}}/{{item.data.c4}}</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c3">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c3}}&nbsp;</span><span v-if="item.data.c3" class="s-target-h-font8">{{item.data.n3}}/{{item.data.c3}}&nbsp;&nbsp;</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c8">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c8}}&nbsp;</span><span v-if="item.data.c8" class="s-target-h-font8">{{item.data.n8}}/{{item.data.c8}}&nbsp;&nbsp;</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c5">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c5}}&nbsp;</span><span v-if="item.data.c5" class="s-target-h-font8">{{item.data.n5}}/{{item.data.c5}}&nbsp;&nbsp;</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c9">
                                        <span class="s-target-h-font7" >{{getStore(item.data.storetype).c9}}&nbsp;</span><span v-if="item.data.c9" class="s-target-h-font8">{{item.data.n5}}/{{item.data.c9}}&nbsp;&nbsp;</span>
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c7">
                                        <span class="s-target-h-font7">인스타그램</span>&nbsp;<span class="s-target-h-font8">{{item.data.n7}}/{{item.data.c7 ? item.data.c7 : 0}}</span>&nbsp;&nbsp;
                                    </a-col>
                                    <a-col :span="12" v-if="item.data.c6">
                                        <span class="s-target-h-font7">네이버블로그</span>&nbsp;<span class="s-target-h-font8">{{item.data.n6}}/{{item.data.c6 ? item.data.c6 : 0}}</span>&nbsp;&nbsp;
                                    </a-col>

                                </a-row>

                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col :span="span" v-for="item in applyDataTemp" :key="item.key" style="padding-right:10px">
                    <div class="s-target-h-b1">
                        <div style="display:flex">
                            <div>
                                <a-tag color="orange">임시저장</a-tag>
                            </div>
                            <div style="right:0;position:absolute;padding-right:30px;margin-top:-5px">
                                <a-dropdown>
                                    <a class="ant-dropdown-link" style="color:black;font-size:20px;font-weight:bold">
                                        <a-icon type="ellipsis" />
                                    </a>
                                    <a-menu slot="overlay" @click="onClickMenuTemp($event,item.key)">
                                        <a-menu-item key="1">임시저장 불러오기</a-menu-item>
                                        <a-menu-item key="2">삭제하기</a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </div>
                        <div>
                            <div class="col-12" style="text-align:center;font-size:20px;margin-top:10px">{{item.data.sname}}&nbsp;[{{item.data.storetype}}]</div>
                            <div class="col-12" style="text-align:center;font-size:25px;font-weight:bold"> {{item.data.keyword}}</div>
                            <div class="col-12" style="text-align:center;font-size:14px;color:#2c2c2c"> {{item.data.eventdttm}}</div>
                            <div class="col-12" style="text-align:center;margin-top:27px;margin-bottom:8px"> {{item.data.desc}}</div>
                        </div>
                    </div>
                </a-col>
            </a-row>

        </a-row>

        <a-modal class="history" centered title="캠페인 지원 명단 조회" v-model="dialogVisiable3" :closable="false" :maskClosable="false" width="1000px">
            <div class="s-h-btn2">
                <a-button style="cursor:pointer;color:white" @click="onExportExcelByCampaign()"><i class="fal fa-file-excel">&nbsp;</i>엑셀 다운로드</a-button>
            </div>
            <a-tabs v-model="activeTabName" v-if="this.selectedStoretype">
                <a-tab-pane :tab="getLabel1" key="first" v-if="getLabel1">
                    <a-table :data-source="campaigndata1" :columns="columnsp" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">
                        <div slot="action" slot-scope="record">
                            <u><a v-if="record.evidence_img" :href="record.evidence_img" style="color:blue;cursor:pointer" target="_blank">인증 이미지 보기</a></u>
                        </div>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel2" key="second" v-if="getLabel2">
                    <a-table :data-source="campaigndata2" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel3" key="third" v-if="getLabel3">
                    <a-table :data-source="campaigndata3" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel4" key="fourth" v-if="getLabel4">
                    <a-table :data-source="campaigndata4" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel8" key="eight" v-if="getLabel8">
                    <a-table :data-source="campaigndata8" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel5" key="five" v-if="getLabel5">
                    <a-table :data-source="campaigndata5" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel9" key="five" v-if="getLabel9">
                    <a-table :data-source="campaigndata5" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel6" key="six" v-if="getLabel6">
                    <a-table :data-source="campaigndata6" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>
                <a-tab-pane :tab="getLabel7" key="seven" v-if="getLabel7">
                    <a-table :data-source="campaigndata7" :columns="columns" height="500" style="width: 100%" :row-key="record => record.id" :scroll="{ x: 400 }">

                    </a-table>
                </a-tab-pane>

            </a-tabs>

            <span slot="footer" class="dialog-footer">
                <a-button style="background-color:#0264fb;color:white" @click="dialogVisiable3 = false">닫기</a-button>
            </span>
        </a-modal>

        <a-modal title="지원 이력" centered v-model="dialogVisiable4" :closable="false" :maskClosable="false" width="1000px">
            <a-row v-if="this.selectedStoretype">
                <a-col :span="24">
                    <h6>주문 번호 : {{selectedApplyData.order}} </h6>
                </a-col>
                <a-col :span="24">
                    <h6>스토어타입 : {{selectedApplyData.storetype}} </h6>
                </a-col>
                <a-col :span="24">
                    <h6>주문 URL </h6>
                    <a :href="selectedApplyData.url" target="_blank"> {{selectedApplyData.url}}</a>
                </a-col>
                <a-col :span="24" v-if="selectedApplyData.preview">
                    <img style="width:100%" :src="selectedApplyData.preview" />
                </a-col>
                <a-col :span="24" v-else>
                    <a-row style="padding:10px;background-color:#fafafa;margin-top:20px">
                        <a-col :span="4">
                            지표
                        </a-col>
                        <a-col :span="4">
                            신청수
                        </a-col>
                        <a-col :span="4">
                            제품비용
                        </a-col>
                        <a-col :span="4">
                            서비스비용
                        </a-col>
                        <a-col :span="4">
                            개당비용<br />(제품+서비스비용)
                        </a-col>
                        <a-col :span="4">
                            총가격(신청수X비용)
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c1}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c1}}개
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.price+selectedApplyData.dprice).toLocaleString()}}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(buypay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.price+selectedApplyData.dprice+parseInt(buypay) ).toLocaleString()}}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p1).toLocaleString()}}원
                        </a-col>
                    </a-row>

                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c2">
                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c2}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c2}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(textpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(textpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p2).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c3">
                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c3}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c3}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(photopay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(photopay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p3).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c8">
                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c8}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c8}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(videopay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(videopay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p8).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c4">

                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c4}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c4}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(zzimpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(zzimpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p4).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c5">
                        <a-col :span="4">
                            {{this.getStore(this.selectedStoretype).c5}}
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c5}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(qapay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(qapay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p5).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c9">
                        <a-col :span="4">
                            회원가입
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c9}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(regpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(regpay).toLocaleString() }}원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p9).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c6">
                        <a-col :span="4">
                            네이버 블로그
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c6}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            10,000원
                        </a-col>
                        <a-col :span="4">
                            10,000원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p6).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c7">
                        <a-col :span="4">
                            인스타그램
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c7}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            5,000원
                        </a-col>
                        <a-col :span="4">
                            5,000원
                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.p7).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            총계
                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.c1}}개
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.original).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            예치금
                        </a-col>
                        <a-col :span="4">
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.deposit).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            쿠폰
                        </a-col>
                        <a-col :span="4">
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{selectedApplyData.couponPay}}원
                        </a-col>
                    </a-row>

                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            총합
                        </a-col>
                        <a-col :span="4">
                            총계-쿠폰
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.tp).toLocaleString()}}원
                        </a-col>
                    </a-row>
                    <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                        <a-col :span="4">
                            최종
                        </a-col>
                        <a-col :span="4">
                            총합(VAT)-예치금
                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">

                        </a-col>
                        <a-col :span="4">
                            {{parseInt(selectedApplyData.tp2).toLocaleString()}}원
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
            <template slot="footer">
                <a-button key="submit" type="primary" @click="dialogVisiable4=false">닫기</a-button>
            </template>
        </a-modal>

    </a-layout-content>
</a-layout>
</template>

<script>
var allCampaign = null;

Array.prototype.keySort = function(keys) {
  keys = keys || {};

  // via
  // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
  var obLen = function(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  // avoiding using Object.keys because I guess did it have IE8 issues?
  // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
  // whatever
  var obIx = function(obj, ix) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (size == ix) return key;
        size++;
      }
    }
    return false;
  };

  var keySort = function(a, b, d) {
    d = d !== null ? d : 1;
    // a = a.toLowerCase(); // this breaks numbers
    // b = b.toLowerCase();
    if (a == b) return 0;
    return a > b ? 1 * d : -1 * d;
  };

  var KL = obLen(keys);

  if (!KL) return this.sort(keySort);

  for (var k in keys) {
    // asc unless desc or skip
    keys[k] =
      keys[k] == "desc" || keys[k] == -1
        ? -1
        : keys[k] == "skip" || keys[k] === 0 ? 0 : 1;
  }

  this.sort(function(a, b) {
    var sorted = 0,
      ix = 0;

    while (sorted === 0 && ix < KL) {
      var k = obIx(keys, ix);
      if (k) {
        var dir = keys[k];
        sorted = keySort(a[k], b[k], dir);
        ix++;
      }
    }
    return sorted;
  });
  return this;
};
import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  getCurrentTimeNew,
  getCurrentTime,
  getHex,
  replaceAll,
  getCurrentDate3From,
  getCurrentDate3To,
  isMobile,
  showError,
  showSuccess,
  ShoppingMall
} from "../components/fnc.js";
import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
export default {
  data() {
    return {
      gowner: "이름",
      gid: "아이디",
      span: 8,
      dialogVisiable4: false,
      dialogVisiable3: false,
      activeTabName: "first",
      applyTitle: "",
      groupdata: [],
      ShoppingMall: ShoppingMall,
      storelist: [
        {
          value: "전체",
          label: "전체"
        },
        {
          value: ShoppingMall.NAVER_STORE,
          label: ShoppingMall.NAVER_STORE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
        },
        {
          value: ShoppingMall.NAVER_WINDOW,
          label: ShoppingMall.NAVER_WINDOW,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver-beauty%20(1).png?alt=media&token=fecfc02e-3890-41de-a763-62421e6b0afa"
        },
        {
          value: ShoppingMall.NAVER_CROWD,
          label: ShoppingMall.NAVER_CROWD,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
        },
        {
          value: ShoppingMall.NAVER_PLACE,
          label: ShoppingMall.NAVER_PLACE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-place.png?alt=media&token=4c41313a-f501-477a-8f0c-8474ace3b4b2"
        },
        {
          value: ShoppingMall.COUPANG,
          label: ShoppingMall.COUPANG,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-coupang.png?alt=media&token=8f9fcc4a-911c-4e8a-8b3b-b9c82ae8cd8c"
        },
        {
          value: ShoppingMall.KAKAO,
          label: ShoppingMall.KAKAO,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-kakao.png?alt=media&token=c1404cd1-bf08-4594-9f4a-e70751c3e31d"
        },
        {
          value: ShoppingMall.TALK,
          label: ShoppingMall.TALK,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-talk.png?alt=media&token=9343a52b-1c9f-42b6-9ef2-7f4ba294d8f1"
        },
        {
          value: ShoppingMall.CJ,
          label: ShoppingMall.CJ,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-cj.png?alt=media&token=c73bd252-1cdd-4b7a-a13e-e22748c19bcc"
        },
        {
          value: ShoppingMall.ELEVEN,
          label: ShoppingMall.ELEVEN,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-11.png?alt=media&token=d9a1f10f-805b-4ada-9367-906f47240691"
        },
        {
          value: ShoppingMall.GMARKET,
          label: ShoppingMall.GMARKET,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-g.png?alt=media&token=dd336c8d-b45d-490b-adde-cd783224e831"
        },
        {
          value: ShoppingMall.AUCTION,
          label: ShoppingMall.AUCTION,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-a.png?alt=media&token=5f055dfa-71f7-49ba-97d5-6cfb5968d9a0"
        },
        {
          value: ShoppingMall.GSSHOP,
          label: ShoppingMall.GSSHOP,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-gsshop.png?alt=media&token=fa83709c-3176-4728-9f26-114156134b8a"
        },
        {
          value: ShoppingMall.TMON,
          label: ShoppingMall.TMON,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-tmon.png?alt=media&token=e94b55ad-6ad9-46aa-9ea6-4907667ad368"
        },
        {
          value: ShoppingMall.WEMAFE,
          label: ShoppingMall.WEMAFE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wemafe.png?alt=media&token=91b750f8-8fd1-4089-b9d9-d0408350495c"
        },
        {
          value: ShoppingMall.WADIZ,
          label: ShoppingMall.WADIZ,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wadiz.png?alt=media&token=bd4ac3b0-816c-41cf-9ecb-df871be02de2"
        },
        {
          value: ShoppingMall.BAEMIN,
          label: ShoppingMall.BAEMIN,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-bamin.png?alt=media&token=4b64aaaa-2ea6-4245-ac84-4413b97805b7"
        }
      ],
      date: {
        fromdate: moment(new Date().addDays(-300)),
        todate: moment(new Date().addDays(1)),
        fromdateD: new Date().addDays(-300),
        todateD: new Date().addDays(1)
      },
      columns: [
        {
          dataIndex: "date",
          key: "date",
          title: "진행날짜",
          width: 200
        },
        {
          dataIndex: "owner",
          key: "owner",
          title: "이름",
          width: 200
        },
        {
          dataIndex: "id",
          key: "id",
          title: "아이디 혹은 계정명",
          width: 300
        },
        {
          dataIndex: "status",
          key: "status",
          title: "상태",
          width: 100
        }
      ],
      columnsp: [
        {
          dataIndex: "date",
          key: "date",
          title: "진행날짜",
          width: 200
        },
        {
          dataIndex: "owner",
          key: "owner",
          title: "이름",
          width: 200
        },
        {
          dataIndex: "id",
          key: "id",
          title: "아이디 혹은 계정명",
          width: 300
        },
        {
          dataIndex: "status",
          key: "status",
          title: "상태",
          width: 100
        },
        {
          key: "action",
          title: "증빙자료",
          width: 150,
          scopedSlots: {
            customRender: "action"
          }
        }
      ],
      storetype: "전체",
      selectedStoretype: "",
      locale,
      selectedApplyData: {},
      applyData: [],
      applyDataTemp: [],
      applyDataSelect: [],
      campaigndata1: [],
      campaigndata2: [],
      campaigndata3: [],
      campaigndata4: [],
      campaigndata5: [],
      campaigndata6: [],
      campaigndata7: [],
      campaigndata8: [],
      c1: "구매",
      n1: 0,
      t1: 0,
      c2: "텍스트리뷰",
      n2: 0,
      t2: 0,
      c3: "포토리뷰",
      n3: 0,
      t3: 0,
      c4: "찜하기",
      n4: 0,
      t4: 0,
      c5: "Q&A",
      n5: 0,
      t5: 0,
      c6: "네이버블로그",
      n6: 0,
      t6: 0,
      c7: "인스타그램",
      n7: 0,
      t7: 0,
      c8: "동영상리뷰",
      n8: 0,
      t8: 0,
      c9: "회원가입",
      n9: 0,
      t9: 0,
      e1: [],
      e2: [],
      e3: [],
      e4: [],
      e5: [],
      e6: [],
      e7: [],
      e8: [],
      stack: 0,
      buypay: "0",
      textpay: "0",
      photopay: "0",
      videopay: "5000",
      zzimpay: "0",
      qapay: "0",
      regpay: "0",
      naverpay: "10000",
      instagrampay: "5000",
      stackindex: []
    };
  },
  components: {
    NavFooter
  },
  mounted() {
    this.initStoreList();
    this.onLoadDraft();
    fbq("track", "PageView");
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getPhone() {
      return this.$store.state.AppActiveUser.phone;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getIsAgency() {
      return this.$store.state.AppActiveUser.isagency;
    },
    getLabel1() {
      var _title = this.getStore(this.selectedStoretype).c1;
      if (_title) {
        return _title + " [" + this.n1 + "/" + this.t1 + "]";
      } else {
        return null;
      }
    },
    getLabel2() {
      var _title = this.getStore(this.selectedStoretype).c2;
      if (_title && this.t2) {
        return _title + " [" + this.n2 + "/" + this.t2 + "]";
      } else {
        return null;
      }
    },
    getLabel3() {
      var _title = this.getStore(this.selectedStoretype).c3;
      if (_title && this.t3) {
        return _title + " [" + this.n3 + "/" + this.t3 + "]";
      } else {
        return null;
      }
    },
    getLabel4() {
      var _title = this.getStore(this.selectedStoretype).c4;
      if (_title && this.t4) {
        return _title + " [" + this.n4 + "/" + this.t4 + "]";
      } else {
        return null;
      }
    },
    getLabel5() {
      var _title = this.getStore(this.selectedStoretype).c5;
      if (_title && this.t5) {
        return _title + " [" + this.n5 + "/" + this.t5 + "]";
      } else {
        return null;
      }
    },
    getLabel6() {
      if (this.t6) {
        return "네이버블로그 [" + this.n6 + "/" + this.t6 + "]";
      } else {
        return null;
      }
    },
    getLabel7() {
      if (this.t7) {
        return "인스타그램 [" + this.n7 + "/" + this.t7 + "]";
      } else {
        return null;
      }
    },
    getLabel8() {
      var _title = this.getStore(this.selectedStoretype).c8;
      if (_title && this.t8) {
        return _title + " [" + this.n8 + "/" + this.t8 + "]";
      } else {
        return null;
      }
    },
    getLabel9() {
      var _title = this.getStore(this.selectedStoretype).c9;
      if (_title && this.t9) {
        return _title + " [" + this.n5 + "/" + this.t9 + "]";
      } else {
        return null;
      }
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;

      if (this.$vssWidth < 992) {
        this.span = 24;
      } else if (this.$vssWidth < 1300) {
        this.span = 8;
      } else {
        this.span = 6;
      }
      return _mobile;
    }
  },
  methods: {
    getStore(_storetype) {
      var _r = this.storelist.find(item => item.value == _storetype);
      if (_r) {
        return _r["missionTitle"];
      } else {
        return this.storelist[0]["missionTitle"];
      }
    },
    initStoreList() {
      for (var i = 0; i < this.storelist.length; i++) {
        this.storelist[i]["missionTitle"] = {
          c1: "구매",
          c2: "텍스트리뷰",
          c3: "포토리뷰",
          c4: "찜",
          c5: "Q&A",
          c8: "동영상리뷰",
          c9: "회원가입"
        };
      }

      //Naver Place
      this.storelist[4]["missionTitle"] = {
        c1: "네이버예약",
        c2: "영수증리뷰",
        c3: "",
        c4: "플레이스저장",
        c5: "",
        c8: "",
        c9: ""
      };

      // //Wadiz
      this.storelist[15]["missionTitle"] = {
        c1: "펀딩",
        c2: "응원리뷰",
        c3: "",
        c4: "찜",
        c5: "지지서명",
        c8: "",
        c9: ""
      };
    },
    onExportExcelByCampaign() {
      var date = new Date();
      var self = this;
      this.e1 = [["날짜", this.gowner, this.gid, "상태"]];
      this.e2 = [["날짜", this.gowner, this.gid, "상태"]];
      this.e3 = [["날짜", this.gowner, this.gid, "상태"]];
      this.e4 = [["날짜", this.gowner, this.gid, "상태"]];
      this.e5 = [["날짜", this.gowner, this.gid, "상태"]];
      this.e6 = [["날짜", "이름", "아이디", "상태"]];
      this.e7 = [["날짜", "이름", "아이디", "상태"]];
      this.e8 = [["날짜", "이름", "아이디", "상태"]];

      var wb = XLSX.utils.book_new();

      this.campaigndata1.forEach(item => {
        this.e1.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata2.forEach(item => {
        this.e2.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata3.forEach(item => {
        this.e3.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata4.forEach(item => {
        this.e4.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata5.forEach(item => {
        this.e5.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata6.forEach(item => {
        this.e6.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata7.forEach(item => {
        this.e7.push([item.date, item.owner, item.id, item.status]);
      });
      this.campaigndata8.forEach(item => {
        this.e8.push([item.date, item.owner, item.id, item.status]);
      });

      var ws1 = XLSX.utils.aoa_to_sheet(this.e1);
      XLSX.utils.book_append_sheet(
        wb,
        ws1,
        this.getStore(this.selectedStoretype).c1
      );

      if (this.getStore(this.selectedStoretype).c2) {
        var ws2 = XLSX.utils.aoa_to_sheet(this.e2);
        XLSX.utils.book_append_sheet(
          wb,
          ws2,
          this.getStore(this.selectedStoretype).c2
        );
      }

      if (this.getStore(this.selectedStoretype).c3) {
        var ws3 = XLSX.utils.aoa_to_sheet(this.e3);
        XLSX.utils.book_append_sheet(
          wb,
          ws3,
          this.getStore(this.selectedStoretype).c3
        );
      }

      if (this.getStore(this.selectedStoretype).c8) {
        var ws8 = XLSX.utils.aoa_to_sheet(this.e8);
        XLSX.utils.book_append_sheet(
          wb,
          ws8,
          this.getStore(this.selectedStoretype).c8
        );
      }

      if (this.getStore(this.selectedStoretype).c4) {
        var ws4 = XLSX.utils.aoa_to_sheet(this.e4);
        XLSX.utils.book_append_sheet(
          wb,
          ws4,
          this.getStore(this.selectedStoretype).c4
        );
      }

      if (this.getStore(this.selectedStoretype).c5) {
        var ws5 = XLSX.utils.aoa_to_sheet(this.e5);
        XLSX.utils.book_append_sheet(
          wb,
          ws5,
          this.getStore(this.selectedStoretype).c5
        );
      }

      var ws6 = XLSX.utils.aoa_to_sheet(this.e6);
      XLSX.utils.book_append_sheet(wb, ws6, "네이버블로그");

      var ws7 = XLSX.utils.aoa_to_sheet(this.e7);
      XLSX.utils.book_append_sheet(wb, ws7, "인스타그램");

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });

      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          "스토어링크-캠페인." + date.getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    onLoadGroupView() {
      var self = this;
      this.$confirm({
        title: "알림",
        content:
          "해당 기능은 캠페인에 연동되어 있는 모든 정보를 일괄 다운로드 받는 기능이라 데이타 량에 따라 최대 1분 까지 걸릴수 있습니다. 계속 진행하시겠습니까?",
        okText: "네",
        cancelText: "아니오",
        onOk() {
          self.groupdata = [];

          self.$vs.loading();

          var _idx_ = 0;

          allCampaign = new Map();
          self.stack = 0;

          self.applyData.filter(item => {
            var _tmp = item.data.cid.split(",");
            if (item.data.chkd) {
              for (var i = 0; i < _tmp.length; i++) {
                if (_tmp[i]) {
                  self.stack++;
                }
              }
            }
          });

          self.applyData.filter(item => {
            if (item.data.chkd) {
              var _tmp = item.data.cid.split(",");

              if (item.data.cid) {
                setTimeout(() => {
                  for (var i = 0; i < _tmp.length; i++) {
                    self.stackindex[_idx_] = 1;
                    if (_tmp[i]) {
                      var _cid_ = _tmp[i].replace(/ /g, "");
                      self.onLoadCampaignContentsGroup(_cid_, item.data, _idx_);
                    }
                  }
                  _idx_++;
                }, 1000 * _idx_);
              }
            }
          });
          setTimeout(() => {
            self.$vs.loading.close();
          }, 2000);
        },
        onCancel() {}
      });
    },
    async onLoadCampaignContentsGroup(cid, master, _idx_) {
      var db = firebase.firestore();
      var self = this;

      await db
        .collection("pugshop_mission_summary")
        .where("campaignId", "==", cid.toString())
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _data = doc.data().o;

            _data.forEach(item => {
              var _idx = 0;
              var __name = item.tname;
              var __naverId = item.naver_id;
              var _mcd = item.mall_cd;
              if (_mcd == "0302") {
                __naverId = item.coupang_id;
              } else if (_mcd == "0303") {
                __naverId = item.cj_mall_id;
              } else if (_mcd == "0304") {
                __naverId = item.eleven_st_id;
              } else if (_mcd == "0305") {
                __naverId = item.gmarket_id;
              }

              var __regDate = item.mod_date
                ? getCurrentTime(new Date(item.mod_date.seconds * 1000))
                : "";

              var __applyStateCd = item.applier_mission_state_cd;
              var __applyDesc = "";

              var __name2 = item.oname;

              if (__applyStateCd == "0901") {
                __applyDesc = "완료";
              } else if (__applyStateCd == "0902") {
                __applyDesc = "미진행";
              } else if (__applyStateCd == "0903") {
                __applyDesc = "삭제";
              } else if (__applyStateCd == "0904") {
                __applyDesc = "확인중";
              }

              var _item = {
                sr: master.order,
                storetype: master.storetype,
                storename: master.sname,
                srstatus: master.status,
                manager: master.managername,
                code: master.sn,
                cid: cid,
                keyword: master.keyword,
                desc: master.desc,
                buy: master.c1,
                date: __regDate,
                sdate: __regDate,
                status: __applyDesc,
                owner:
                  item.mtype == "0503" ? __name + "[" + __name2 + "]" : __name,
                id: __naverId,
                no: self.stackindex[_idx_],
                blogId: item.naver_blog_id,
                blogCount: item.naver_blog_count,
                blogIdRef: "https://blog.naver.com/" + item.naver_blog_id,
                instaRef: "https://www.instagram.com/" + item.instagram_id,
                instaId: item.instagram_id,
                instaCount: item.instagram_count
              };

              if (__applyStateCd == "0901" || __applyStateCd == "0902") {
                if (item.mtype == "0503") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[0] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }

                  self.stackindex[_idx_]++;
                } else if (item.mtype == "0504") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[1] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0505") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[2] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0501") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[3] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0502") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[4] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0507") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[5] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0506") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[6] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {}
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                } else if (item.mtype == "0508") {
                  var _dump = allCampaign.get(
                    _item.id + _item.cid + __name + _item.sr
                  );

                  if (_dump) {
                    //overwrite
                    _dump[7] = {
                      obj: _item
                    };
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dump
                    );
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      }
                    ];
                    allCampaign.set(
                      _item.id + _item.cid + __name + _item.sr,
                      _dummy_
                    );
                    //set
                  }
                }
              }
            });
          });
        });
      self.stack--;
      if (self.stack <= 0) {
        setTimeout(() => {
          self.$vs.loading.close();

          var _idx_ = 0;
          var _sr_ = "";
          allCampaign.forEach(item => {
            var _dummy = item;

            var _d = {
              sr: _dummy[0].obj.sr,
              storetype: _dummy[0].obj.storetype,
              storename: _dummy[0].obj.storename,
              srstatus: _dummy[0].obj.srstatus,

              manager: _dummy[0].obj.manager,
              code: _dummy[0].obj.code,
              keyword: _dummy[0].obj.keyword,
              desc: _dummy[0].obj.desc,
              buy: _dummy[0].obj.buy,
              date: _dummy[0].obj.date,
              owner: _dummy[0].obj.owner,
              id: _dummy[0].obj.id,
              no: _dummy[0].obj.no,

              c1: _dummy[0].obj.status,
              c2: _dummy[1].obj ? _dummy[1].obj.status : "-",
              c3: _dummy[2].obj ? _dummy[2].obj.status : "-",
              c4: _dummy[3].obj ? _dummy[3].obj.status : "-",
              c5: _dummy[4].obj ? _dummy[4].obj.status : "-",
              c6: _dummy[5].obj ? _dummy[5].obj.status : "-",
              c7: _dummy[6].obj ? _dummy[6].obj.status : "-",
              c8: _dummy[7].obj ? _dummy[7].obj.status : "-",
              c1date: _dummy[0].obj ? _dummy[0].obj.sdate : "-",
              c2date: _dummy[1].obj ? _dummy[1].obj.sdate : "-",
              c3date: _dummy[2].obj ? _dummy[2].obj.sdate : "-",
              c4date: _dummy[3].obj ? _dummy[3].obj.sdate : "-",
              c5date: _dummy[4].obj ? _dummy[4].obj.sdate : "-",
              c6date: _dummy[5].obj ? _dummy[5].obj.sdate : "-",
              c7date: _dummy[6].obj ? _dummy[6].obj.sdate : "-",
              c8date: _dummy[7].obj ? _dummy[7].obj.sdate : "-"
            };
            self.groupdata.push(_d);
          });
          self.onExportCampaign();
        }, 1000);
      }
    },
    onExportCampaign() {
      /* generate workbook object from table */
      var date = new Date();

      var wb = XLSX.utils.book_new();

      var _data = [];

      _data = [
        [
          "SR번호",
          "스토어타입",
          "스토어명",
          "캠페인상태",
          "담당자",
          "제품코드",
          "키워드",
          "제품명",
          "구매수",
          "캠페인날짜",
          "참여자",
          "아이디",
          "순번",
          "구매",
          "미션타입1",
          "미션타입2",
          "미션타입3",
          "미션타입4",
          "미션타입5",
          "미션타입6",
          "미션타입7"
        ]
      ];

      var sorto = {
        sr: "desc",
        no: "asc"
      };

      this.groupdata.keySort(sorto);

      this.groupdata.forEach(item => {
        var _status_ = "";
        if (item.srstatus == "A") {
          _status_ = "지원[미결제]";
        }
        if (item.srstatus == "C") {
          _status_ = "결제완료";
        }
        if (item.srstatus == "E") {
          _status_ = "이벤트지원";
        }
        if (item.srstatus == "F") {
          _status_ = "승인중";
        }
        if (item.srstatus == "W") {
          _status_ = "결제오픈대기완료";
        }
        if (item.srstatus == "P") {
          _status_ = "진행중";
        }
        if (item.srstatus == "B") {
          _status_ = "진행중";
        }
        if (item.srstatus == "T") {
          _status_ = "진행중";
        }
        if (item.srstatus == "X") {
          _status_ = "캠페인완료";
        }
        _data.push([
          item.sr,
          item.storetype,
          item.storename,
          _status_,
          item.manager,
          item.code,
          item.keyword,
          item.desc,
          item.buy,
          item.date,
          item.owner,
          item.id,
          item.no,
          item.c1 + "\n[" + item.c1date + "]",
          item.c2,
          item.c3,
          item.c8,
          item.c4,
          item.c5,
          item.c6,
          item.c7
        ]);
      });

      var ws1 = XLSX.utils.aoa_to_sheet(_data);

      // var ws2 = XLSX.utils.aoa_to_sheet(ws_data);

      XLSX.utils.book_append_sheet(wb, ws1, "데이타");
      // XLSX.utils.book_append_sheet(wb, ws2, 't2');

      // var wb = XLSX.utils.table_to_book(document.querySelector("#single"));

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });

      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          "스토어링크-캠페인결과" + date.getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    onSelectCheckBox(e) {
      this.applyData = this.applyData.filter(ii => {
        ii.data["chkd"] = e.target.checked;

        return ii;
      });
    },
    onLoadSimpleCampaignContents(_sysId, _obj, cid) {
      var db = firebase.firestore();
      var self = this;

      db
        .collection("pugshop_mission_summary")
        .where("campaignId", "==", cid.toString())
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _data = doc.data().o;

            _data.forEach(item => {
              var _idx = 0;
              var __applyStateCd = item.applier_mission_state_cd;

              if (__applyStateCd == "0901") {
                _idx = 1;
              }

              if (__applyStateCd != "0903") {
                if (item.mtype == "0503") {
                  _obj["n1"] = _obj["n1"] + _idx;
                } else if (item.mtype == "0504") {
                  _obj["n2"] = _obj["n2"] + _idx;
                } else if (item.mtype == "0505") {
                  _obj["n3"] = _obj["n3"] + _idx;
                } else if (item.mtype == "0501") {
                  _obj["n4"] = _obj["n4"] + _idx;
                } else if (item.mtype == "0502") {
                  _obj["n5"] = _obj["n5"] + _idx;
                } else if (item.mtype == "0507") {
                  _obj["n6"] = _obj["n6"] + _idx;
                } else if (item.mtype == "0506") {
                  _obj["n7"] = _obj["n7"] + _idx;
                } else if (item.mtype == "0508") {
                  _obj["n8"] = _obj["n8"] + _idx;
                }
              }
            });

            self.applyData = self.applyData.filter(ii => {
              if (ii.key == _sysId) {
                ii.data = _obj;
              }
              return ii;
            });
          });
        });
    },
    onLoadCampaignContents(cid) {
      var db = firebase.firestore();
      var self = this;
      var _mallType = "";
      db
        .collection("pugshop_mission_summary")
        .where("campaignId", "==", cid.toString())
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _data = doc.data().o;

            _data.forEach(item => {
              var _idx = 0;
              var __name = item.tname;
              var __naverId = item.naver_id;
              var _mcd = item.mall_cd;
              _mallType = _mcd;
              var __name2 = item.oname;

              if (_mcd == "0302") {
                __naverId = item.coupang_id;
              } else if (_mcd == "0303") {
                __naverId = item.cj_mall_id;
              } else if (_mcd == "0304") {
                __naverId = item.eleven_st_id;
              } else if (_mcd == "0305") {
                __naverId = item.gmarket_id;
              } else if (_mcd == "0398") {
                var _evidence = item.evidence_orderer_name
                  ? item.evidence_orderer_name.split(",")
                  : [];

                __naverId = _evidence[0] ? _evidence[0] : item.naver_id;
                __name2 = _evidence[1] ? _evidence[1] : "";
                __name = item.oname;
              }

              var __regDate = "";

              if (item.mod_date) {
                if (item.mod_date.seconds) {
                  __regDate = getCurrentTime(
                    new Date(item.mod_date.seconds * 1000)
                  );
                }
              }

              var __applyStateCd = item.applier_mission_state_cd;
              var __applyDesc = "";

              if (__applyStateCd == "0901") {
                __applyDesc = "완료";
                _idx = 1;
              } else if (__applyStateCd == "0902") {
                __applyDesc = "미진행";
              } else if (__applyStateCd == "0903") {
                __applyDesc = "삭제";
              } else if (__applyStateCd == "0904") {
                __applyDesc = "확인중";
              }

              var _item = {
                date: __regDate,
                owner:
                  item.mtype == "0503" ? __name + "[" + __name2 + "]" : __name,
                id: __naverId,
                status: __applyDesc,
                blogId: item.naver_blog_id,
                blogCount: item.naver_blog_count,
                evidence_img: item.evidence_img ? item.evidence_img : "",
                blogIdRef: "https://blog.naver.com/" + item.naver_blog_id,
                instaRef: "https://www.instagram.com/" + item.instagram_id,
                instaId: item.instagram_id,
                instaCount: item.instagram_count,
                applier_id: item.applier_id
              };

              if (__applyStateCd != "0903") {
                if (item.mtype == "0503") {
                  self.campaigndata1.push(_item);
                  self.n1 = self.n1 + _idx;
                } else if (item.mtype == "0504") {
                  self.campaigndata2.push(_item);
                  self.n2 = self.n2 + _idx;
                } else if (item.mtype == "0505") {
                  self.campaigndata3.push(_item);
                  self.n3 = self.n3 + _idx;
                } else if (item.mtype == "0501") {
                  self.campaigndata4.push(_item);
                  self.n4 = self.n4 + _idx;
                } else if (item.mtype == "0502") {
                  self.campaigndata5.push(_item);
                  self.n5 = self.n5 + _idx;
                } else if (item.mtype == "0507") {
                  self.campaigndata6.push(_item);
                  self.n6 = self.n6 + _idx;
                } else if (item.mtype == "0506") {
                  self.campaigndata7.push(_item);
                  self.n7 = self.n7 + _idx;
                } else if (item.mtype == "0508") {
                  self.campaigndata8.push(_item);
                  self.n8 = self.n8 + _idx;
                }
              }
            });

            if (_mallType == "0398") {
              self.onReplaceHeader();
              self.campaigndata1.forEach(ii => {
                var _swap2 = self.campaigndata2;

                self.campaigndata2 = _swap2.filter(item => {
                  if (item.applier_id == ii.applier_id) {
                    item.owner = ii.owner;
                    item.id = ii.id;
                  }
                  return item;
                });

                var _swap5 = self.campaigndata5;

                self.campaigndata5 = _swap5.filter(item => {
                  if (item.applier_id == ii.applier_id) {
                    item.owner = ii.owner;
                    item.id = ii.id;
                  }
                  return item;
                });
              });
            }
          });
        });
    },
    loadDefaultInfo() {
      var self = this;
      self.applyData = [];
      var sref = firebase.database().ref("rank_apply/");
      this.$vs.loading();

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _d_ = sdatas.val().eventdttm;
            var _storetype = sdatas.val().storetype;
            if (self.storetype == "전체") {
              //skip
            } else {
              _storetype = self.storetype;
            }

            if (_storetype == sdatas.val().storetype) {
              if (
                _d_ >= getCurrentDate3From(self.date.fromdateD) &&
                _d_ <= getCurrentDate3To(self.date.todateD) &&
                sdatas.val().status != "R" &&
                sdatas.val().status != "D"
              ) {
                var _data = sdatas.val();

                if (sdatas.child("original").exists()) {
                  //skip
                } else {
                  _data["original"] = sdatas.val().tp2;
                }

                _data["p8"] = sdatas.val().p8 ? sdatas.val().p8 : 0;
                _data["p9"] = sdatas.val().p9 ? sdatas.val().p9 : 0;

                _data["c8"] = sdatas.val().c8 ? sdatas.val().c8 : 0;
                _data["c9"] = sdatas.val().c9 ? sdatas.val().c9 : 0;

                _data["n1"] = 0;
                _data["n2"] = 0;
                _data["n3"] = 0;
                _data["n4"] = 0;
                _data["n5"] = 0;
                _data["n6"] = 0;
                _data["n7"] = 0;
                _data["n8"] = 0;
                _data["n9"] = 0;

                _data["chkd"] = true;

                var _tmp = [];

                if (sdatas.val().cid) {
                  _tmp = replaceAll(sdatas.val().cid, " ", "").split(",");
                }

                self.applyData.push({
                  key: sdatas.key,
                  data: _data
                });
                self.onPreLoadCampaignApplyCount(sdatas.key, _data, _tmp);

                // self.applyDataSelect.push({
                //   key: sdatas.key,
                //   data: _data
                // });
              }
            }
          });
          self.$vs.loading.close();
        });
    },

    onPreLoadCampaignApplyCount(_key, _data, _tmp) {
      for (var i = 0; i < _tmp.length; i++) {
        if (_tmp[i]) {
          var _cid_ = _tmp[i].replace(/ /g, "");
          this.onLoadSimpleCampaignContents(_key, _data, _cid_);
        }
      }
    },

    onLoadDraft() {
      var self = this;

      var db = firebase.firestore();
      var self = this;

      db
        .collection("rank_apply_draft")
        .where("uid", "==", this.getUid)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (doc.data()) {
              var _data = doc.data();

              if (doc.data().original) {
                //skip
              } else {
                _data["original"] = doc.data().tp2;
              }

              // if (self.getUid == doc.data().agency || doc.data().is3 == "T") {
              //   _data["p1"] =
              //     (doc.data().price + doc.data().dprice + 3000) * doc.data().c1;

              //   _data["p2"] = 0;
              //   _data["p3"] = 0;
              //   _data["p4"] = 0;
              //   _data["p5"] = 0;
              //   _data["p8"] = 0;
              //   _data["p9"] = 0;
              // } else if (self.getUid == doc.data().agency) {
              //   _data["p1"] =
              //     (doc.data().price + doc.data().dprice + 4000) * doc.data().c1;

              //   _data["p2"] = 0;
              //   _data["p3"] = 0;
              //   _data["p4"] = 0;
              //   _data["p5"] = 0;
              //   _data["p8"] = 0;
              // }
              self.applyDataTemp.push({
                key: doc.id,
                data: _data
              });
            }
          });
          self.loadDefaultInfo();
        });
    },
    onClickMenu(e, key) {
      var self = this;
      if (e.key == "1") {
        this.dialogVisiable4 = true;
        var _t = this.applyData.find(item => item.key == key);
        this.selectedApplyData = {};

        if (_t) {
          this.selectedApplyData = _t.data;

          this.selectedApplyData["p6"] = _t.data.p6 ? _t.data.p6 : 0;
          this.selectedApplyData["p7"] = _t.data.p7 ? _t.data.p7 : 0;
          this.selectedApplyData["c6"] = _t.data.c6 ? _t.data.c6 : 0;
          this.selectedApplyData["c7"] = _t.data.c7 ? _t.data.c7 : 0;
          this.selectedApplyData["c8"] = _t.data.c8 ? _t.data.c8 : 0;
          this.selectedApplyData["c9"] = _t.data.c9 ? _t.data.c9 : 0;

          this.selectedStoretype = _t.data.storetype;

          this.text = null;
          this.photo = null;
          this.video = null;
          this.zzim = null;
          this.qa = null;
          this.naver = null;
          this.instagram = null;
          this.reg = null;

          if (
            this.selectedStoretype == ShoppingMall.NAVER_PLACE &&
            this.getIsAgency == "T"
          ) {
            this.buypay = "5000";
          } else {
            if (this.getIsAgency == "T") {
              this.buypay = "4000";
            } else if (this.getIsAgency == "3") {
              this.buypay = "3000";
            } else if (this.getIsAgency == "2.5") {
              this.buypay = "2500";
            } else if (this.selectedStoretype == ShoppingMall.NAVER_PLACE) {
              this.buypay = "10000";
            } else if (this.selectedStoretype == ShoppingMall.WADIZ) {
              this.buypay = "7000";
            } else {
              this.buypay = "5000";
            }
          }

          if (this.selectedStoretype == ShoppingMall.NAVER_PLACE) {
            this.textpay = "0";
          } else if (
            this.getIsAgency == "T" ||
            this.getIsAgency == "3" ||
            this.getIsAgency == "2.5"
          ) {
            this.textpay = "0";
          } else {
            this.textpay = "1000";
          }

          if (
            this.getIsAgency == "T" ||
            this.getIsAgency == "3" ||
            this.getIsAgency == "2.5"
          ) {
            this.photopay = "0";
          } else {
            this.photopay = "2000";
          }

          this.videopay = "5000";

          if (this.selectedStoretype == ShoppingMall.NAVER_PLACE) {
            this.zzimpay = "0";
          } else if (
            this.getIsAgency == "T" ||
            this.getIsAgency == "3" ||
            this.getIsAgency == "2.5"
          ) {
            this.zzimpay = "0";
          } else {
            this.zzimpay = "1000";
          }

          if (this.selectedStoretype == ShoppingMall.WADIZ) {
            this.qapay = "2000";
          } else if (
            this.getIsAgency == "T" ||
            this.getIsAgency == "3" ||
            this.getIsAgency == "2.5"
          ) {
            this.qapay = "0";
          } else if (this.selectedStoretype == ShoppingMall.WADIZ) {
            this.qapay = "2000";
          } else {
            this.qapay = "1000";
          }

          if (this.selectedStoretype.substr(0, 3) == "자사몰") {
            this.regpay = "1000";
          }
        }
      }
      if (e.key == "5") {
        let routeData = this.$router.resolve({
          name: "report",
          query: {
            cid: key
          }
        });
        window.open(routeData.href, "_blank");
      }
      if (e.key == "6") {
        window.open(
          "https://apply-summary.web.app/#/report?cid=" + key,
          "_blank"
        );
      }
      if (e.key == "4") {
        var val = {
          key: key
        };
        this.$router.push({
          name: "target",
          params: {
            objs: val
          }
        });
      }
      if (e.key == "3") {
        //new popup
        var _obj = this.applyData.find(item => item.key == key);
        this.n1 = 0;
        this.n2 = 0;
        this.n3 = 0;
        this.n4 = 0;
        this.n5 = 0;
        this.n6 = 0;
        this.n7 = 0;
        this.n8 = 0;
        this.n9 = 0;

        this.t1 = 0;
        this.t2 = 0;
        this.t3 = 0;
        this.t4 = 0;
        this.t5 = 0;
        this.t6 = 0;
        this.t7 = 0;
        this.t8 = 0;
        this.t9 = 0;

        // var _t = this.applyData.find(item => item.key == key);

        if (_obj) {
          this.t1 = _obj.data.c1;
          this.t2 = _obj.data.c2;
          this.t3 = _obj.data.c3;
          this.t4 = _obj.data.c4;
          this.t5 = _obj.data.c5;
          this.selectedStoretype = _obj.data.storetype;

          if (_obj.data.c6) {
            this.t6 = _obj.data.c6;
          }
          if (_obj.data.c7) {
            this.t7 = _obj.data.c7;
          }
          if (_obj.data.c8) {
            this.t8 = _obj.data.c8;
          }
          if (_obj.data.c9) {
            this.t9 = _obj.data.c9;
          }
        }

        var _tmp = replaceAll(_obj.data.cid, " ", "").split(",");

        this.campaigndata1 = [];
        this.campaigndata2 = [];
        this.campaigndata3 = [];
        this.campaigndata4 = [];
        this.campaigndata5 = [];
        this.campaigndata6 = [];
        this.campaigndata7 = [];
        this.campaigndata8 = [];
        this.stack = 0;
        for (var i = 0; i < _tmp.length; i++) {
          if (_tmp[i]) {
            this.stack++;
            var _cid_ = _tmp[i].replace(/ /g, "");
            this.onLoadCampaignContents(_cid_);
          }
        }
        this.dialogVisiable3 = true;
      }
      if (e.key == "2") {
        this.$confirm({
          title: "확인",
          content: "해당 지원 신청 항목을 삭제 하시겠습니까?.",
          okText: "삭제",
          cancelText: "취소",
          onOk() {
            var uref2 = firebase.database().ref("rank_apply/" + key + "/");

            var upData2 = {
              status: "D"
            };
            uref2.update(upData2);

            showSuccess({
              notify: self.$vs.notify,
              msg: "성공적으로 삭제 되었습니다."
            });

            self.onDeleteCouponHistory(key);

            self.applyData = self.applyData.filter(item => {
              if (item.key == key) {
                item.data.status = "D";
              }
              return item;
            });
          },
          onCancel() {}
        });
      }
    },
    onDeleteCouponHistory(key) {
      var sref = firebase.database().ref("coupon_user/");
      sref
        .orderByChild("uid")
        .equalTo(this.getUserKey)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _rid = sdatas.child("rid").exists() ? sdatas.val().rid : "-";

            if (_rid == key) {
              var sref2 = firebase.database().ref("coupon_user/" + sdatas.key);

              var upData2 = {
                flag: "N"
              };
              sref2.update(upData2);
            }
          });
        });
    },
    onReplaceHeader() {
      this.gowner = "퍼그샵[펀딩번호]";
      this.gid = "주문자";
      this.columns = [
        {
          dataIndex: "date",
          key: "date",
          title: "진행날짜",
          width: 200
        },
        {
          dataIndex: "owner",
          key: "owner",
          title: "퍼그샵[펀딩번호]",
          width: 200
        },
        {
          dataIndex: "id",
          key: "id",
          title: "주문자",
          width: 300
        },
        {
          dataIndex: "status",
          key: "status",
          title: "상태",
          width: 100
        }
      ];

      this.columnsp = [
        {
          dataIndex: "date",
          key: "date",
          title: "진행날짜",
          width: 200
        },
        {
          dataIndex: "owner",
          key: "owner",
          title: "퍼그샵[펀딩번호]",
          width: 200
        },
        {
          dataIndex: "id",
          key: "id",
          title: "주문자",
          width: 300
        },
        {
          dataIndex: "status",
          key: "status",
          title: "상태",
          width: 100
        },
        {
          key: "action",
          title: "증빙자료",
          width: 150,
          scopedSlots: {
            customRender: "action"
          }
        }
      ];
    },
    onClickMenuTemp(e, key) {
      var self = this;
      if (e.key == "1") {
        let routeData = this.$router.resolve({
          name: "target",
          query: {
            temp: "t"
          }
        });
        window.open(routeData.href, "_self");
      }
      if (e.key == "2") {
        //remove

        this.$confirm({
          title: "확인",
          content: "해당 임시 저장 항목을 삭제 하시겠습니까?.",
          okText: "삭제",
          cancelText: "취소",
          onOk() {
            var db = firebase.firestore();

            const user = firebase.auth().currentUser;
            if (user) {
              db
                .collection("rank_apply_draft")
                .doc(user.uid)
                .delete()
                .then(function() {
                  showSuccess({
                    notify: self.$vs.notify,
                    msg: "성공적으로 삭제 되었습니다."
                  });

                  self.applyDataTemp = self.applyDataTemp.filter(item => {
                    if (item.key == key) {
                      //
                    } else {
                      return item;
                    }
                  });
                });
            }
          },
          onCancel() {}
        });
      }
    },
    disabledDateEx(current) {
      return current && current < moment(new Date().addDays(-365));
    },
    onChangeDateFrom(e) {
      if (e) {
        this.date.fromdateD = e._d;
      }
      // this.date.fromdateD = e._d;
    },
    onChangeDateTo(e) {
      if (e) {
        this.date.todateD = e._d;
      }
    },
    onSelectStoreType(value) {
      this.storetype = value;
    }
  }
};
</script>

<style>
.s-target-h-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
}

.s-target-h-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-target-h-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-target-h-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #0264fb;
}

.s-target-h-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-target-h-font6 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.s-target-h-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-target-h-font8 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #0264fb;
}

.history .ant-input,
.history .ant-btn {
  height: 40px;
}

.history .ant-btn:hover {
  color: white;
}

.s-h-btn1 button {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
  color: white;
}

.s-h-btn2 button {
  height: 40px;
  border-radius: 5px;
  background-color: #0dab48 !important;
  color: white;
}

.s-target-h-b1 {
  width: 100%;
  height: 334px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-dropdown-menu {
  border: 1px solid #0264fb;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu:hover {
  background-color: white !important;
  color: #0264fb !important;
}

.history.mobile {
  margin-left: 10px;
}

.history:not(.mobile) {
  margin-left: 50px;
}

.history .ant-tabs-tab {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.history .ant-tabs-tab:hover {
  color: #0264fb !important;
}

.history .ant-tabs-tab-active {
  color: #0264fb !important;
}

.history .ant-tabs-ink-bar {
  background-color: #0264fb !important;
  height: 4px !important;
}

.history .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0264fb;
  border-color: #0264fb;
}

.smalllogo {
  width: 26px;
  height: 26px;
  margin-top: -4px;
  margin-right: 5px;
}
</style>
